import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'
import Layout from '../components/Layout'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeart} from '@fortawesome/free-solid-svg-icons'

export default class IndexPage extends React.Component {
    render() {

        return (
            <Layout>

                <header className="masthead" style={{backgroundColor:"#44378D"}}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 mx-auto">
                                <div className="site-heading">
                                    <span className="subheading">Conseils écrits avec <FontAwesomeIcon icon={faHeart}/> par <Link to="https://www.conity.fr">Conity</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            </Layout>
        )
    }
}

IndexPage.propTypes = {
    data: PropTypes.shape({
                              allMarkdownRemark: PropTypes.shape({
                                                                     edges: PropTypes.array,
                                                                 }),
                          }),
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
